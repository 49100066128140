/* eslint-disable */
// this is an auto generated file. This will be overwritten



export const updateEndUserSpecialPricing = /* GraphQL */ `
  mutation updateEndUserSpecialPricing(
    $input:UpdateEndUserSpecialPricingInput!
  ) {
      updateEndUserSpecialPricing(input: $input) {
        ItemCode
        OrderCode
        Price
      EndUserCode
      EndUserWholesRate
      id
    }
  }
`;
export const updateArInventory = /* GraphQL */ `
  mutation UpdateArInventory(
    $input: UpdateArInventoryInput!
    $condition: ModelArInventoryConditionInput
  ) {
    updateArInventory(input: $input, condition: $condition) {
      id
      Agency_Price_Group
      Product_Code
      Supplier
      Product_Name_j
      Quantity
      Capacity_Display
      Catarog_Price
      Wholes_Rate
      Wholes_Price
      Delivery_Term
      Storage_Temp
      Capacity_Unit
      Capacity_Value
      Product_Name_e
      Plant_Name
      Wharehouse_Name
      Category1
      Category2
      Product_Group1
      Product_Group2
      Supplier_LotNo
      Expiration_date
      TempInventoryQuantity
      lastupdate
      updatedAt
      createdAt
    }
  }
`;
export const deleteArInventory = /* GraphQL */ `
  mutation DeleteArInventory(
    $input: DeleteArInventoryInput!
    $condition: ModelArInventoryConditionInput
  ) {
    deleteArInventory(input: $input, condition: $condition) {
      id
      Agency_Price_Group
      Product_Code
      Supplier
      Product_Name_j
      Quantity
      Capacity_Display
      Catarog_Price
      Wholes_Rate
      Wholes_Price
      Delivery_Term
      Storage_Temp
      Capacity_Unit
      Capacity_Value
      Product_Name_e
      Plant_Name
      Wharehouse_Name
      Category1
      Category2
      Product_Group1
      Product_Group2
      Supplier_LotNo
      Expiration_date
      lastupdate
      updatedAt
      createdAt
    }
  }
`;
export const createArAgency = /* GraphQL */ `
  mutation CreateArAgency(
    $input: CreateArAgencyInput!
    $condition: ModelArAgencyConditionInput
  ) {
    createArAgency(input: $input, condition: $condition) {
      Agency_id
      SubId
      Agency_Price_Group
      Company_Name
      eMail
      updatedAt
      createdAt
    }
  }
`;

export const updateArAgency = /* GraphQL */ `
  mutation UpdateArAgency(
    $input: UpdateArAgencyInput!
    $condition: ModelArAgencyConditionInput
  ) {
    updateArAgency(input: $input, condition: $condition) {
      Agency_id
      SubId
      Agency_Price_Group
      Company_Name
      eMail
      updatedAt
      createdAt
    }
  }
`;
export const deleteArAgency = /* GraphQL */ `
  mutation DeleteArAgency(
    $input: DeleteArAgencyInput!
    $condition: ModelArAgencyConditionInput
  ) {
    deleteArAgency(input: $input, condition: $condition) {
      Agency_id
      SubId
      Agency_Price_Group
      Company_Name
      eMail
      updatedAt
      createdAt
    }
  }
`;
// export const createArCampaign = /* GraphQL */ `
//   mutation CreateArCampaign(
//     $input: CreateArCampaignInput!
//     $condition: ModelArCampaignConditionInput
//   ) {
//     createArCampaign(input: $input, condition: $condition) {
//       id
//       Title
//       TermFrom
//       TermTo
//       Text
//       updatedAt
//       createdAt
//     }
//   }
// `;
export const createArCampaign = /* GraphQL */ `
  mutation CreateArCampaign(
    $input: CreateArCampaignInput!
    $condition: ModelArCampaignConditionInput
  ) {
    createArCampaign(input: $input, condition: $condition) {
      id
      Title
      Term_From
      Term_To
      Wholes_Rate_Condision
      Text
      Discription
      Notification
      Remarks
      filePDF
      PDFurl
      updatedAt
      createdAt
      UserAuth
      fileName
    }
  }
`;
export const deleteArCampaign = /* GraphQL */ `
  mutation DeleteArCampaign(
    $input: DeleteArCampaignInput!
    $condition: ModelArCampaignConditionInput
  ) {
    deleteArCampaign(input: $input, condition: $condition) {
      id
      Title
      Term_From
      Term_To
      Wholes_Rate_Condision
      Text
      Discription
      Notification
      Remarks
      filePDF
      PDFurl
      updatedAt
      createdAt
      UserAuth
      fileName
    }
  }
`;

// export const updateArCampaign = /* GraphQL */ `
//   mutation UpdateArCampaign(
//     $input: UpdateArCampaignInput!
//     $condition: ModelArCampaignConditionInput
//   ) {
//     updateArCampaign(input: $input, condition: $condition) {
//       id
//       Title
//       TermFrom
//       TermTo
//       Text
//       updatedAt
//       createdAt
//     }
//   }
// `;


export const updateArCampaign = /* GraphQL */ `
  mutation UpdateArCampaign(
    $input: UpdateArCampaignInput!
    $condition: ModelArCampaignConditionInput
  ) {
    updateArCampaign(input: $input, condition: $condition) {
      id
      Title
      Term_From
      Term_To
      Wholes_Rate_Condision
      Text
      Discription
      Notification
      Remarks
      filePDF
      PDFurl
      updatedAt
      createdAt
      UserAuth
      fileName
    }
  }
`;

export const createArOrder = /* GraphQL */ `
  mutation CreateArOrder(
    $input: CreateArOrderInput!
    $condition: ModelArOrderConditionInput
  ) {
    createArOrder(input: $input, condition: $condition) {
      id
      OrderStatus
      QuoteNumber
      AgencyID
      Insertperson
      OrderNumber
      DesiredDeliveryDate
      ChouNumber
      OrderPerson
      RegistrationDate
      ChouDate
      CompanyName
      EstimatedShippingDate
      ShipDate
      GroupName
      ARBSalesRepresentative
      DeliveryYtDate
      DeliveryDate
      TelNo
      Email
      InvoicePayTotal
      comments
      ShippingBlock
      EndUserId
      EndUserNm
      Orderitem
      ProductName
      OrderOrderQuantity  
      FixedQuantity
      OrderChargeName
      OrderRemarks
      OrderShipContactNm
      OrderShippingNotes
      OrderTelNo
      OrderAddress
      OrderPostCode
      ShippingOrderNumber
    }
  }
`;
export const updateArOrder = /* GraphQL */ `
  mutation UpdateArOrder(
    $input: UpdateArOrderInput!
    $condition: ModelArOrderConditionInput
  ) {
    updateArOrder(input: $input, condition: $condition) {
      id
      OrderStatus
      QuoteNumber
      AgencyID
      Insertperson
      OrderNumber
      DesiredDeliveryDate
      ChouNumber
      OrderPerson
      RegistrationDate
      ChouDate
      CompanyName
      EstimatedShippingDate
      ShipDate
      GroupName
      ARBSalesRepresentative
      DeliveryYtDate
      DeliveryDate
      TelNo
      Email
      InvoicePayTotal
      Contractor
      ShippingDestination
      ContractorNm
      EndUserId
      EndUserNm
      ShippingDestinationNm
      comments
      ShippingBlock
      OrderChargeName
      OrderRemarks
      OrderShipContactNm
      OrderShippingNotes
      OrderTelNo
      OrderAddress
      OrderPostCode
      ShippingOrderNumber
    }
  }
`;
export const deleteArOrder = /* GraphQL */ `
  mutation deleteArOrder(
    $input: DeleteArOrderInput!
    $condition: ModelArOrderConditionInput
  ) {
    deleteArOrder(input: $input, condition: $condition) {
      id
      QuoteNumber
    }
  }
`;
export const createArAgencyOrderInfo = /* GraphQL */ `
  mutation CreateArAgencyOrderInfo(
    $input: CreateArAgencyOrderInfoInput!
    $condition: ModelArAgencyOrderInfoConditionInput
  ) {
    createArAgencyOrderInfo(input: $input, condition: $condition) {
      id
      Agency_id
      AgencyName
      AgencyEmail
      GroupName
      QuoteNumber
      Product_Code
      Product_Name_j
      Product_id
      OrderQuantity
      Wholes_Rate
      Wholes_Price
      Price
      Campaign_price
      Product_id
      Quantity
      delflg
      DetailNo
      Purchasing_Document_Type
      Purchasing_Organization
      purchasing_Group
      Supplier
      Tax_Code
      Storage_Location
      Sales_Document_Type
      invoiceSubtotal
      campaignPriceTotal
      EndUserPriceTotal
      quantityPriceTotal
      invoiceFax
      invoicePayTotal
      unitPrice
      invoiceTotal
      ShippingDestination
      ShippingDestinationNm
      EndUserId
      EndUserNm
      Contractor
      ContractorNm
      RegQty
      BulkSales
      SalesUnitPriceScale
      PurchUnitPriceScale
      ShortestRefTime
      DTStockTier
      QuotationReplyLT
      DTNormalLT
      FukuiSeinoLT
      DropDeliveryTime
      SalesType
      UnitPriceAftDis
      InHouseRe
      FukuiWarehouseCSVRe
      PurchOrderRe
      TorayEDIRe
      PriceTypePurchase
      Capacity
SuppName
BulkShippingFee
CoolDeliveryCharge
CapacityUnit
PriceUnit
UserAuth
SelectContractor
SelectContractorNm
Quote_reg_flag
Sales_confirm_flag
CompanMemo
SalespersonName
Salesperson
SubId
TorayShipCode
Plant
Basic_Quantity_Unit
SalesGroup
SalesRepresentative
EndUserIdDirectDelivery
User_Name
AssigneeCode
    }
  }
`;
export const updateArAgencyOrderInfo = /* GraphQL */ `
  mutation UpdateArAgencyOrderInfo(
    $input: UpdateArAgencyOrderInfoInput!
    $condition: ModelArAgencyOrderInfoConditionInput
  ) {
    updateArAgencyOrderInfo(input: $input, condition: $condition) {
      id
      Agency_id
      QuoteNumber
      Product_Code
      Product_Name_j
      OrderQuantity
      Wholes_Rate
      Wholes_Price
      Campaign_price
      SalesGroup
      Quantity
      Product_id
      delflg
      OutputFlag
      DetailNo
      Purchasing_Document_Type
      Purchasing_Organization
      purchasing_Group
      Supplier
      Tax_Code
      Storage_Location
      Sales_Document_Type
      Storage_Location_U
      Basic_Quantity_Unit
      Salesperson
      Segment
      Agency_Price_Group
      OrderDetailsNumber
      RegistrationDate
      AgencyName
      AgencyEmail
      GroupName
      DesiredDeliveryDate
      OrderNumber
      ShippingBlock
      ShippingDestination
      ShippingDestinationNm
      Contractor
      ContractorNm
      EndUserId
      EndUserNm
      CompanyName
      OrderRemarks
      OrderShippingNotes
      OrderTelNo
      OrderAddress
      OrderPostCode
      ShippingOrderNumber
      OrderChargeName
      OrderStatus
      OrderShipContactNm
      comments
      invoiceSubtotal
      campaignPriceTotal
      EndUserPriceTotal
      quantityPriceTotal
      invoiceFax
      invoicePayTotal
      unitPrice
      invoiceTotal
      SalesUnitPriceScale
      PurchUnitPriceScale
      ShortestRefTime
      RegQty
      BulkSales
      DTStockTier
      QuotationReplyLT
      DTNormalLT
      FukuiSeinoLT
      DropDeliveryTime
      SalesType
      UnitPriceAftDis
      InHouseRe
      FukuiWarehouseCSVRe
      PurchOrderRe
      TorayEDIRe
      PriceTypePurchase
      Capacity
SuppName
BulkShippingFee
CoolDeliveryCharge
CapacityUnit
PriceUnit
UserAuth
SelectContractor
SelectContractorNm
Quote_reg_flag
Sales_confirm_flag
CompanMemo
SalespersonName
SubId
TorayShipCode
Plant
SalesRepresentative
EndUserIdDirectDelivery
User_Name
AssigneeCode
    }
  }
`;
export const deleteArAgencyOrderInfo = /* GraphQL */ `
  mutation deleteArAgencyOrderInfo(
    $input: DeleteArAgencyOrderInfoInput!
    $condition: ModelArAgencyOrderInfoConditionInput
  ) {
    deleteArAgencyOrderInfo(input: $input, condition: $condition) {
      id
      QuoteNumber
    }
  }
`;
// export const createArAddress = /* GraphQL */ `
//   mutation createArAddress(
//     $input: CreateArAddressInput!
//     $condition: ModelArAddressConditionInput
//   ) {
//     createArAddress(input: $input, condition: $condition) {
//       id
//       Agency_id
//       RegisteredPerson
//       ProcurementStaff
//       CompanyName
//       SalesOfficeName
//       ARBSalesRepresentative
//       PhoneNumber
//       EmailAddress
//       updatedAt
//       createdAt
//     }
//   }
// `;
// export const deleteArAddress = /* GraphQL */ `
//   mutation DeleteArAddress(
//     $input: DeleteArAddressInput!
//     $condition: ModelArAddressConditionInput
//   ) {
//     deleteArAddress(input: $input, condition: $condition) {
//       id
//       Agency_id
//       RegisteredPerson
//       ProcurementStaff
//       CompanyName
//       SalesOfficeName
//       ARBSalesRepresentative
//       PhoneNumber
//       EmailAddress
//       updatedAt
//       createdAt
//     }
//   }
// `;

// export const updateArAddress = /* GraphQL */ `
//   mutation UpdateArAddress(
//     $input: UpdateArAddressInput!
//     $condition: ModelArAddressConditionInput
//   ) {
//     updateArAddress(input: $input, condition: $condition) {
//       id
//       Agency_id
//       RegisteredPerson
//       ProcurementStaff
//       CompanyName
//       SalesOfficeName
//       ARBSalesRepresentative
//       PhoneNumber
//       EmailAddress
//       updatedAt
//       createdAt
//     }
//   }
// `;

export const createArCustomer = /* GraphQL */ `
  mutation createArCustomer(
    $input: CreateArCustomerInput!
    $condition: ModelArCustomerConditionInput
  ) {
    createArCustomer(input: $input, condition: $condition) {
      id
      CustomerCodeKey
      AccounKey
      CustomerCode
      CustomerAccountGroup
      HonorificTitleKey
      Name1
      Name2
      Name3
      SearchTerm1
      PlaceName
      PostCode
      CountryCode
      Area
      AddressTmeZone
      LanguageCode
      FirstPhoneNumber
      AccountingCode
      InternationalPart1
      InternationalPart2
      InternationalCheckDigit
      AllocationTohierarchy
      AnnualSales
      SalesYear
      NumberOfEmployees
      Registrationyear
      NonMilitaryUse
      ExportControlTDODate
      ExportControlSDNCheckDate
      ExportControlListConfirmationDate
      TransactionClassification
      
    }
  }
`;
export const deleteArCustomer = /* GraphQL */ `
  mutation DeleteArCustomer(
    $input: DeleteArCustomerInput!
    $condition: ModelArCustomerConditionInput
  ) {
    deleteArCustomer(input: $input, condition: $condition) {
      id
      CustomerCodeKey
      AccounKey
      CustomerCode
      CustomerAccountGroup
      HonorificTitleKey
      Name1
      Name2
      Name3
      SearchTerm1
      PlaceName
      PostCode
      CountryCode
      Area
      AddressTmeZone
      LanguageCode
      FirstPhoneNumber
      AccountingCode
      InternationalPart1
      InternationalPart2
      InternationalCheckDigit
      AllocationTohierarchy
      AnnualSales
      SalesYear
      NumberOfEmployees
      Registrationyear
      NonMilitaryUse
      ExportControlTDODate
      ExportControlSDNCheckDate
      ExportControlListConfirmationDate
      TransactionClassification
      
    }
  }
`;

export const updateArCustomer = /* GraphQL */ `
  mutation updateArCustomer(
    $input: UpdateArCustomerInput!
    $condition: ModelArCustomerConditionInput
  ) {
    updateArCustomer(input: $input, condition: $condition) {
      id
      CustomerCodeKey
      AccounKey
      CustomerCode
      CustomerAccountGroup
      HonorificTitleKey
      Name1
      Name2
      Name3
      SearchTerm1
      PlaceName
      PostCode
      CountryCode
      Area
      AddressTmeZone
      LanguageCode
      FirstPhoneNumber
      AccountingCode
      InternationalPart1
      InternationalPart2
      InternationalCheckDigit
      AllocationTohierarchy
      AnnualSales
      SalesYear
      NumberOfEmployees
      Registrationyear
      NonMilitaryUse
      ExportControlTDODate
      ExportControlSDNCheckDate
      ExportControlListConfirmationDate
      TransactionClassification      
    }
  }
`;


export const updateArEndUser = /* GraphQL */ `
  mutation updateArEndUser(
    $input: UpdateArEndUserInput!
  ) {
    updateArEndUser(input: $input) {
      EndUserCode
      Name1
      Name2
      Name3
      Name4
      OrderRecipientCode
      OrderRecipientName
      SearchTerm
      id
      TorayEndUser
  TorayEndUserBranchName
  TorayEndUserName
    }
  }
`;

export const updateArCustomerAddress = /* GraphQL */ `
  mutation  updateArCustomerAddress(
    $input: UpdateArCustomerAddressInput!
  ) {
    updateArCustomerAddress(input: $input) {
      id
      city
      TransactionClassification
      Tel
      SearchTerm1
      PostCode
      PlaceName3
      PlaceName
      OutFlag
      OrderRecipientName
      OrderRecipientCode
      Name4
      Name3
      Name2
      Name1
      Fax
      CustomerReceiptCode
      CustomerCodeKey
      AreaCode
      Area
    }
  }
`;

export const CreateArCustomerAddress = /* GraphQL */ `
  mutation createArCustomerAddress(
    $input: CreateArCustomerAddressInput!
  ) {
    createArCustomerAddress(input: $input) {
      id
      CustomerReceiptCode
      Name1
      Name2
      Name3
      Name4
      SearchTerm1
      PostCode
      AreaCode
      Area
      PlaceName
      PlaceName3
      city
      Tel
      Fax
      TransactionClassification
      OrderRecipientCode
      OutFlag
      CustomerCodeKey
    }
  }
`;

// export const updateArAZCustomerCode = /* GraphQL */ `
//   mutation updateArAZCustomerCode(
//     $input: UpdateArAZCustomerCodeInput!
//   ) {
//     updateArAZCustomerCode(input: $input) {
//       id
//       CustomerCodeNumber
//     }
//   }
// `;
export const deleteArCustomerAddress = /* GraphQL */ `
  mutation deleteArCustomerAddress(
    $input: DeleteArCustomerAddressInput!
  ) {
    deleteArCustomerAddress(input: $input) {
    Area
    CustomerCodeKey
    AreaCode
    CustomerReceiptCode
    Fax
    Name2
    Name1
    Name3
    Name4
    OrderRecipientCode
    OutFlag
    PlaceName
    PlaceName3
    PostCode
    SearchTerm1
    Tel
    TransactionClassification
    city
    id
    }
  }
`;  
export const createArPriceInfo = /* GraphQL */ `
  mutation createArPriceInfo(
    $input: CreateArPriceInfoInput!
  ) {
    createArPriceInfo(input: $input) {
	UseGroupClassification
	ItemCode
	MaterialCode
	ItemName
	SalesDocType
	SuppCode
	SuppName
	SuppGroup
	OrderReceiptCode
	OrderName
	ShipmentCode
	DestName
	EUARBmgmtCode
	EndUserName
	TorayEndUser
	TorayEndUserName
	Capacity
	CapacityUnit
	PriceUnit
	RegQty
	OrdQty
	DTDelOrderRec
	PriceTypeSales
	PriceTypePurchase
	SalesUnitPriceScale1
	SalesUnitPriceScale2
	SalesUnitPriceScale3
	SalesUnitPriceScale4
	SalesUnitPriceScale5
	SalesUnitPriceUnitScale1
	SalesUnitPriceUnitScale2
	SalesUnitPriceUnitScale3
	SalesUnitPriceUnitScale4
	SalesUnitPriceUnitScale5
	PurchUnitPriceScale1
	PurchUnitPriceScale2
	PurchUnitPriceScale3
	PurchUnitPriceScale4
	PurchUnitPriceScale5
	PurchUnitPriceUnitScale1
	PurchUnitPriceUnitScale2
	PurchUnitPriceUnitScale3
	PurchUnitPriceUnitScale4
	PurchUnitPriceUnitScale5
	BulkSales
	BulkShippingFee
	CoolDeliveryCharge
	SalespersonCode
	LastOrderDate
	WebOrderNumber
	ExpirationStart
	Expired
	RecordSalesDate
	RecordSalesApplicable
	PurchaseAccountDate
	AcctForPurchasesApplicableble
	SalesRevRate
	SalesRevPrice
	PurchaseRevRate
	PurchaseRevPrice
	ActualFlag
	HistoryNum
	OrderQuantity
	Remarks
	UpDailyPayment
	UpTime
	SeqNo
	QueryId
  id
    }
  }
`;
export const updateArPriceInfo = /* GraphQL */ `
  mutation updateArPriceInfo(
    $input: UpdateArPriceInfoInput!
  ) {
    updateArPriceInfo(input: $input) {
    BulkSales
    DTDelOrderRec
    EUARBmgmtCode
    ExpirationStart
    Expired
    HistoryNum
    ItemCode
    OrderQuantity
    OrderReceiptCode
    PriceTypePurchase
    PriceTypeSales
    UseGroupClassification
    ItemCode
    MaterialCode
    ItemName
    SalesDocType
    SuppCode
    SuppName
    SuppGroup
    OrderName
    ShipmentCode
    DestName
    EndUserName
    TorayEndUser
    TorayEndUserName
    Capacity
    CapacityUnit
    PriceUnit
    RegQty
    OrdQty
    SalesUnitPriceScale1
    SalesUnitPriceScale2
    SalesUnitPriceScale3
    SalesUnitPriceScale4
    SalesUnitPriceScale5
    SalesUnitPriceUnitScale1
    SalesUnitPriceUnitScale2
    SalesUnitPriceUnitScale3
    SalesUnitPriceUnitScale4
    SalesUnitPriceUnitScale5
    PurchUnitPriceScale1
    PurchUnitPriceScale2
    PurchUnitPriceScale3
    PurchUnitPriceScale4
    PurchUnitPriceScale5
    PurchUnitPriceUnitScale1
    PurchUnitPriceUnitScale2
    PurchUnitPriceUnitScale3
    PurchUnitPriceUnitScale4
    PurchUnitPriceUnitScale5
    BulkShippingFee
    CoolDeliveryCharge
    SalespersonCode
    LastOrderDate
    WebOrderNumber
    RecordSalesDate
    RecordSalesApplicable
    PurchaseAccountDate
    AcctForPurchasesApplicableble
    SalesRevRate
    SalesRevPrice
    PurchaseRevRate
    PurchaseRevPrice
    ActualFlag
    Remarks
    UpDailyPayment
    UpTime
    SeqNo
    id
    }
  }
`;
export const createArItemMaster = /* GraphQL */ `
  mutation createArItemMaster(
    $input: CreateArItemMasterInput!
  ) {
    createArItemMaster(input: $input) {
      ARBSuppAgencyCode
      BaseNumUnit
      Capacity
      CapacityUnit
      ChilledGoods
      DelFlag
      CustomerLTguidance
      CustomerMOQ
      Expiration
      FreeHoldingdays
      GMID
      HScode
      InventoryTier
      ItemGrp
      ItemGrpName
      ItemText
      LatestLT
      MFGPlant
      OrderAdjustment
      OrderUnitpriceUnit
      OuterBoxSizeWDH
      PriceChangeFactor
      ProductCountry
      ProductSizeWDH
      QuotationReplyLT
      Remark
      SAPCODE
      StandardLeadTime
      StipulateRestdays
      StorageLoc
      StorageTemp
      SuppCode
      SuppName
      USproduct
      UnitPerOrder
      id
    }
  }
`;
export const updateArItemMaster = /* GraphQL */ `
  mutation updateArItemMaster(
    $input: UpdateArItemMasterInput!
  ) {
    updateArItemMaster(input: $input) {
      ARBSuppAgencyCode
      BaseNumUnit
      Capacity
      CapacityUnit
      ChilledGoods
      DelFlag
      CustomerLTguidance
      CustomerMOQ
      Expiration
      FreeHoldingdays
      GMID
      HScode
      InventoryTier
      ItemGrp
      ItemGrpName
      ItemText
      LatestLT
      MFGPlant
      OrderAdjustment
      OrderUnitpriceUnit
      OuterBoxSizeWDH
      PriceChangeFactor
      ProductCountry
      ProductSizeWDH
      QuotationReplyLT
      Remark
      SAPCODE
      StandardLeadTime
      StipulateRestdays
      StorageLoc
      StorageTemp
      SuppCode
      SuppName
      USproduct
      UnitPerOrder
      id
    }
  }
`;
export const deleteArItemMaster = /* GraphQL */ `
  mutation deleteArItemMaster(
    $input: DeleteArItemMasterInput!
  ) {
    deleteArItemMaster(input: $input) {
      id
    }
  }
`;
export const createArPriceInfoInput = /* GraphQL */ `
  mutation createArPriceInfo(
    $input: CreateArPriceInfoInput!
  ) {
    createArPriceInfo(input: $input) {
      UseGroupClassification
      ItemCode
      MaterialCode
      ItemName
      SalesDocType
      SuppCode
      SuppName
      SuppGroup
      OrderReceiptCode
      OrderName
      ShipmentCode
      DestName
      EUARBmgmtCode
      EndUserName
      TorayEndUser
      TorayEndUserName
      Capacity
      CapacityUnit
      PriceUnit
      RegQty
      OrdQty
      DTDelOrderRec
      PriceTypeSales
      PriceTypePurchase
      SalesUnitPriceScale1
      SalesUnitPriceScale2
      SalesUnitPriceScale3
      SalesUnitPriceScale4
      SalesUnitPriceScale5
      SalesUnitPriceUnitScale1
      SalesUnitPriceUnitScale2
      SalesUnitPriceUnitScale3
      SalesUnitPriceUnitScale4
      SalesUnitPriceUnitScale5
      PurchUnitPriceScale1
      PurchUnitPriceScale2
      PurchUnitPriceScale3
      PurchUnitPriceScale4
      PurchUnitPriceScale5
      PurchUnitPriceUnitScale1
      PurchUnitPriceUnitScale2
      PurchUnitPriceUnitScale3
      PurchUnitPriceUnitScale4
      PurchUnitPriceUnitScale5
      BulkSales
      BulkShippingFee
      CoolDeliveryCharge
      SalespersonCode
      LastOrderDate
      WebOrderNumber
      ExpirationStart
      Expired
      RecordSalesDate
      RecordSalesApplicable
      PurchaseAccountDate
      AcctForPurchasesApplicableble
      SalesRevRate
      SalesRevPrice
      PurchaseRevRate
      PurchaseRevPrice
      ActualFlag
      OrderQuantity
      HistoryNum
      Remarks
      UpDailyPayment
      UpTime
      SeqNo
      QueryId
    }
  }
`;
export const updateArPriceInfoInput = /* GraphQL */ `
  mutation updateArPriceInfo(
    $input: UpdateArPriceInfoInput!
  ) {
    updateArPriceInfo(input: $input) {
      UseGroupClassification
      ItemCode
      MaterialCode
      ItemName
      SalesDocType
      SuppCode
      SuppName
      SuppGroup
      OrderReceiptCode
      OrderName
      ShipmentCode
      DestName
      EUARBmgmtCode
      EndUserName
      TorayEndUser
      TorayEndUserName
      Capacity
      CapacityUnit
      PriceUnit
      RegQty
      OrdQty
      DTDelOrderRec
      PriceTypeSales
      PriceTypePurchase
      SalesUnitPriceScale1
      SalesUnitPriceScale2
      SalesUnitPriceScale3
      SalesUnitPriceScale4
      SalesUnitPriceScale5
      SalesUnitPriceUnitScale1
      SalesUnitPriceUnitScale2
      SalesUnitPriceUnitScale3
      SalesUnitPriceUnitScale4
      SalesUnitPriceUnitScale5
      PurchUnitPriceScale1
      PurchUnitPriceScale2
      PurchUnitPriceScale3
      PurchUnitPriceScale4
      PurchUnitPriceScale5
      PurchUnitPriceUnitScale1
      PurchUnitPriceUnitScale2
      PurchUnitPriceUnitScale3
      PurchUnitPriceUnitScale4
      PurchUnitPriceUnitScale5
      BulkSales
      BulkShippingFee
      CoolDeliveryCharge
      SalespersonCode
      LastOrderDate
      WebOrderNumber
      ExpirationStart
      Expired
      RecordSalesDate
      RecordSalesApplicable
      PurchaseAccountDate
      AcctForPurchasesApplicableble
      SalesRevRate
      SalesRevPrice
      PurchaseRevRate
      PurchaseRevPrice
      ActualFlag
      OrderQuantity
      HistoryNum
      Remarks
      UpDailyPayment
      UpTime
      SeqNo
      id
      QueryId
    }
  }
`;
export const DeleteArPriceInfoInput = /* GraphQL */ `
  mutation deleteArPriceInfo(
    $input: DeleteArPriceInfoInput!
  ) {
    deleteArPriceInfo(input: $input) {
      id
    }
  }
`;
export const createMaterialStgLoc = /* GraphQL */ `
  mutation createMaterialStgLoc(
    $input: CreateMaterialStgLocInput!
  ) {
    createMaterialStgLoc(input: $input) {
      ItemCode
      Plant
      PlantName
      SalesDocType
      SalesGroup
      SalesGroupName
      StorageLoc
      StorageLocText
      Text
      id
    }
  }
`;
export const updateMaterialStgLoc = /* GraphQL */ `
  mutation updateMaterialStgLoc(
    $input: UpdateMaterialStgLocInput!
  ) {
    updateMaterialStgLoc(input: $input) {
      ItemCode
      Plant
      PlantName
      SalesDocType
      SalesGroup
      SalesGroupName
      StorageLoc
      StorageLocText
      Text
      id
    }
  }
`;