/* ar-brown App (C) 2021 arbrown.co.jp All rights reserved.
 *******************************************************************************
 * Program ID:  Home.js
 * $Revision:   Ver1.0
 * $Modtime:    $
 *******************************************************************************
 * Author      (作成／更新年月日、担当者)
 *  2021/08/01 Rayoo)li : 新規作成
 *
 * Summary Home画面
 *
 * Instructions / Preconditions
 *  なし
 *
 *******************************************************************************
 */

import userAuth from "../components/userAuth";
import Meta from "../components/Meta";
// import * as React from 'react';
/* コンテナ（画面レイアウト）デザイン */
import { Container, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import BootstrapTable from "react-bootstrap-table-next";

// import { Download } from "react-bootstrap-icons";
// Form
import { makeStyles } from "@material-ui/core/styles";
// Resolvers
import { graphqlOperation } from "@aws-amplify/api";
import { API, Storage } from "aws-amplify";

import React, { useEffect, useState } from "react";
import { listArCampaigns,customerByeMail,queryArcalendarIsWorkday,} from "../graphql/queries";
// import { listArAttentions, listArCampaigns } from '../graphql/queries';
//import ToggleButton from '@material-ui/lab/ToggleButton';
// import ButtonGroup from '@material-ui/core/ButtonGroup';
// import { updateArAttention } from '../graphql/mutations';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { updateArCampaign } from "../graphql/mutations";
import { createArCampaign, deleteArCampaign } from "../graphql/mutations";
import config from "../aws-exports";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { Auth } from "aws-amplify";
import { Select, MenuItem} from "@material-ui/core";
import { FormControl as FormControlsk } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {DatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from 'date-fns/locale/ja';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
// From
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    "& > *": {
      margin: theme.spacing(2),
    },
    //padding: '50px 0px 50px 0px',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "30ch",
  },
  input: {
    // display: 'none',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100ch",
  },
  dialogSelect: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  checkboxLabel:{
    height:30,
    textAlign:"center",
    width: 60,
    display:"inline-block",
    fontSize: '14px',
    fontWeight:"bold",
    border: '3px solid black',
    borderRadius:"6px",
    color: 'black',
    background: "white",
    marginLeft: theme.spacing(1),
  },
  checkboxLabelRight:{
    marginRight: theme.spacing(3)
  },
  textlabelOrder: {
    marginLeft: theme.spacing(1),
    height:'30px',
    lineHeight:"30px",
    width:'5ch',
    color: "black",
    fontWeight: "bold",
    fontSize:'16px',
    alignItems: 'center',
    justifyContent:'center',
    borderColor:"rgb(240,242,245)",
    background:"rgb(240,242,245)"
  },
  notice: {
    // 'padding-top': '0.75em',
    "padding-bottom": "0.5em",
    borderRadius: "0.25em",
    textAlign: "center",
    "text-align": "center",
    color: "purple",
    border: "1px solid purple",
    padding: "0.5em",
    fontSize: "1.5rem",
    "font-weight": 500,
    lineHeight: "1.2",
    "margin-top": "0.5em",
    "margin-bottom": "0.5rem",
    width: "100%",
  },
  formControl: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused .MuiOutlinedInput-input': {
        backgroundColor: 'rgb(240,242,245)', // 调整选择值后的背景色
      },
    },
  },
  DialogformControl: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused .MuiOutlinedInput-input': {
        backgroundColor: 'white', // 调整选择值后的背景色
      },
    },
  },
}));
const headerStyle = {
  backgroundColor: "white",
  borderBottom: "3px solid #804F29",
  fontSize: "80%",
};
// const theme = createMuiTheme(
//   { palette: { primary: { main: '#1976d2' }, }, },
//   jaJP,
// );

// const QUERY = 'QUERY';
// const SET = 'SET';
// const initialInventoryState = { Title: "", Discription: "", Notification: "" };
// const reducer2 = (state, action) => {
//   switch (action.type) {
//     case QUERY:
//       return { ...state, ...action.invs };
//     case SET:
//       return { ...state, ...action.invs };
//     default:
//       return state;
//   }
// };


const defaultSorted = [
  {
    dataField: "createdAt",
    order: "desc",
  },
];
const StyledBadge = withStyles(() => ({
  badge: {
    right: 23,
    top: 10,
    border: `3px solid rgb(247,221,148)`,
    padding: '0 4px',
    color:"#f31f1f",
    fontWeight:"bold",
    fontSize:"15px",
    backgroundColor:"rgb(249,246,246)"
  },
}))(Badge);
const columns = [
  {
    dataField: "", text: "", sort: true, style: { margin: "0px", width: "3%",borderLeft:"none" }, headerStyle:{...headerStyle, borderLeft:"none"},
    formatter: (cellContent, row) => {
      let rowUpdatedAt = moment(row.updatedAt).format('YYYYMMDD')
      let tempDate = new Date(new Date(moment(rowUpdatedAt).format('YYYY/MM/DD')).getTime() - 24 * 60 * 60 * 1000)
      let res = false
      let arr = []
      for (let i = 0; i < 3; i++) {
        tempDate.setDate(tempDate.getDate() + 1);
        if (row.IsWorkday.some(h => new Date(moment(h.Date).format('YYYY/MM/DD')).getTime() === new Date(moment(tempDate).format('YYYY/MM/DD')).getTime())) {
          tempDate.setDate(tempDate.getDate() + 1);
          i--
        }else{
          arr.push(moment(tempDate).format('YYYYMMDD'))
        }
      }
      if(arr[0] <= moment(new Date()).format('YYYYMMDD') && moment(new Date()).format('YYYYMMDD') <= moment(tempDate).format('YYYYMMDD')){
        res = true
      }
      return (
        <StyledBadge badgeContent={"New"} color='secondary' style={{display:res === true ? "block" : "none",cursor:"default"}}/>
      )
  }
},
  { dataField: "id", text: "ID", hidden: true, sort: true, headerStyle },
  { dataField: "Term_From", text: "掲載日", sort: true, format: "yyyy/mm/dd", style: { margin: "0px", width: "10%" }, headerStyle,
  formatter: (cellContent, row) => {
    return (
      <div>{moment(row.Term_From).format('YYYY/MM/DD')}</div>
    )
} },
  { dataField: "Term_From", text: "開始", sort: true, hidden: true, format: "yyyy/mm/dd" },
  { dataField: "Title", text: "部門", sort: true, style: { margin: "0px", width: "12%" }, headerStyle },
  {
    dataField: "Text", text: "タイトル", sort: true, style: { margin: "0px", width: "20%" }, headerStyle,
    formatter: (cellContent, row) => {
        return (
          <div>
              {row.Text}
          </div>
        );
    },
  },
  {
    dataField: "PDFurl", text: "添付ファイル", sort: true, style: { margin: "0px", width: "60%" }, headerStyle,
    formatter: (cellContent, row) => {
      if (row.fileName) {
        let filePDFs = JSON.parse(row.PDFurl)
        let fileNames = JSON.parse(row.fileName)
        return (
          <div>
            {fileNames.map((fileName,index)=>{
              return (<a key={index} href={filePDFs[index]} target="_blank" rel="noreferrer">
              {fileName} {index === fileNames.length - 1 ? " " : '、'}
            </a>)
            })}
          </div>
        );
      }
    },
  },
  { dataField: "Term_To", text: "終了", sort: true, hidden: true, format: "yyyy/mm/dd" },
  {
    dataField: "Wholes_Rate_Condision",
    text: "仕切率",
    sort: true,
    hidden: true,
  },
  {
    dataField: "pdf",
    isDummyField: true,
    headerStyle,
    text: "ファイル",
    hidden: true,
    style: { margin: "0px", width: "55%" },
    formatter: (cellContent, row) => {
      if (row.filePDF) {
        return (
          <div>
            <a href={row.PDFurl} target="_blank" rel="noreferrer">
              {/* <Download color="royalblue" size={20} /> */}
              {row.filePDF}
            </a>
          </div>
        );
      }
    },
  },
  {
    dataField: "filePDF",
    text: "PDF",
    align: "right",
    sort: true,
    hidden: true,
  },
];

// 日本語ファイル名のURL記述方法
// Content-Disposition: attachment; filename*=UTF-8''[UTF-8のファイル名をURLエンコードしたもの]
// S3 Api referenc : response-content-language
// Sets the Content-Language header of the response.

// Ezpand row data
// Textのパターンを複数記述しよう！
// Text_h3_1, Text_h3_2, Text_h4_1, Text_h4_2, Text_h5_1, Text_h5_2, Text_h6_1, Text_h6_2
// 常時表示の際はExpand Managment にて記述要
// const expandRow = () => {
//   row => (
//     <div>
//       <h3>{`【${row.Title}】`}</h3>
//       <h3>{`${row.Text_h3_1}`}</h3>
//       <h4>{`${row.Text_h4_1}`}</h4>
//       <h5>{`${row.Text_h5_1}`}</h5>
//       <h6>{`キャンペーン詳細: ${row.Text_h6_1}`}</h6>
//       <div>{`${row.Text}`}</div>
//     </div>
//   )
// };

// const CaptionElement = () => (
//   <h4
//     style={{
//       borderRadius: "0.25em",
//       textAlign: "center",
//       color: "purple",
//       border: "1px solid purple",
//       padding: "0.5em",
//     }}
//   >
//     お知らせ／キャンペーン
//   </h4>
// );
let select_row = [];
const Home = () => {
  // class Home extends React.Component {
  // page content
  const pageTitle = "AR BROWN Order System";
  const classes = useStyles();
  // const [inventories, setInv] = useReducer(reducer2, initialInventoryState);
  const [campaign, setCampaign] = useState([]);
  const [permission,setPermission] = useState("")
  const [departmentCheckbox, setDepartmentCheckbox] = useState({
    outside:false,
    inside:false
  });
  const [dialogDepartmentCheckbox, setDialogDepartmentCheckbox] = useState({
    outside:false,
    inside:false
  });
  const signOut = async () => {
    await Auth.signOut();
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
    document.location.reload();
  };
  const handleOutsideCheckboxChange = async(e)=>{
  try{
    setDepartmentCheckbox({...departmentCheckbox,outside:e.target.checked})
    let newDateStart = FormalizationStart()
    let newDatEnd = FormalizationEnd()
    let filters = {UserAuth:{eq: "1"}}
    if(permission === "2"){
      if(departmentCheckbox.inside === false && e.target.checked === true){
        filters = {UserAuth:{eq: "1"}}
      }else{
        filters = {UserAuth:{ne: null}}
      }
    }else{
        if(departmentCheckbox.inside === false && e.target.checked === true){
        filters = {UserAuth:{eq: "1"},and:[{Term_From: {le: newDateStart}},{Term_To: {ge:newDatEnd}}]}
      }else{
        filters = {UserAuth:{ne: null},and:[{Term_From: {le: newDateStart}},{Term_To: {ge:newDatEnd}}]}
      }
    }
      let dataAll = await getlistArCampaigns(filters,IsWorkday)
      if(selecteDdepartment){
        dataAll = dataAll.filter((item)=>{
            if(item.Title === selecteDdepartment){
              return true
            }else{
              return false
            }
        })}
      setCampaign(dataAll);
  }catch(error){
      if(error.message === 'No current user'){
        //ユーザープールに障害が発生した場合
        console.log('User pool is invalid.Please reauthenticate.');
        //ログイン画面に戻る
        signOut();
        }else{
        //他のエラーを処理する
        console.log('An error occurred:',error);
        }
     }
  }
  const getlistArCampaigns = async(filter,IsWorkday)=>{
    let dataAll = []
  try{
    let filters = filter
    const apiData = await API.graphql(
      graphqlOperation(listArCampaigns, {
        filter: filters,
        limit: 1000,
      }))
      if (apiData.data.listArCampaigns !== null) {
        let nextToken = apiData.data.listArCampaigns.nextToken;
        dataAll = apiData.data.listArCampaigns.items;
        while (nextToken !== null) {
          let apiData2 = await API.graphql(
            graphqlOperation(listArCampaigns, {
              filter:filters,
              limit: 1000,
              nextToken: nextToken,
            }))
          nextToken = apiData2.data.listArCampaigns.nextToken;
          for (
            let i = 0;
            i < apiData2.data.listArCampaigns.items.length;
            i++
          ) {
            dataAll.push(apiData2.data.listArCampaigns.items[i]);
          }
        }
      }
      await Promise.all(
        dataAll.map(async (campaign) => {
          if (campaign.filePDF) {
            let filePDFs = JSON.parse(campaign.filePDF)
            let pdfurl = []
            for(let i = 0 ;i < filePDFs.length;i++){
              if(filePDFs[i]){
                pdfurl.push(await Storage.get(filePDFs[i]))
              }
            }
            campaign.PDFurl = JSON.stringify(pdfurl);
            await API.graphql({
              query: updateArCampaign,
              variables: { input: campaign },
            });
          }
          campaign.Term_From = moment(campaign.Term_From).format("YYYY-MM-DD");
          campaign.Term_To = moment(campaign.Term_To).format("YYYY-MM-DD");
          return campaign;
        })
      );
    }catch(error){
      if(error.message === 'No current user'){
        //ユーザープールに障害が発生した場合
        console.log('User pool is invalid.Please reauthenticate.');
        //ログイン画面に戻る
        signOut();
        }else{
        //他のエラーを処理する
        console.log('An error occurred:',error);
        }
     }
     dataAll.forEach((item)=>{
      item.IsWorkday = IsWorkday
    })
     return dataAll
  }
  const handleInsideCheckboxChange = async(e)=>{
  try{
    setDepartmentCheckbox({...departmentCheckbox,inside:e.target.checked})
    let newDateStart = FormalizationStart()
    let newDatEnd = FormalizationEnd()
    let filters = {UserAuth:{ne: null}}
    if(permission === "2"){
      if(departmentCheckbox.outside === true && e.target.checked === false){
        filters = {UserAuth:{eq: "1"}}
      }else{
        filters = {UserAuth:{ne: null}}
      }
    }else{
      if(departmentCheckbox.outside === true && e.target.checked === false){
        filters = {UserAuth:{eq: "1"},and:[{Term_From: {le: newDateStart}},{Term_To: {ge:newDatEnd}}]}
      }else{
          filters = {UserAuth:{ne: null},and:[{Term_From: {le: newDateStart}},{Term_To: {ge:newDatEnd}}]}
      }
    }
    let dataAll = await getlistArCampaigns(filters,IsWorkday)
    if(selecteDdepartment){
      dataAll = dataAll.filter((item)=>{
          if(item.Title === selecteDdepartment){
            return true
          }else{
            return false
          }
      })}
    setCampaign(dataAll);
  }catch(error){
      if(error.message === 'No current user'){
        //ユーザープールに障害が発生した場合
        console.log('User pool is invalid.Please reauthenticate.');
        //ログイン画面に戻る
        signOut();
        }else{
        //他のエラーを処理する
        console.log('An error occurred:',error);
        }
     }
  }
  const handleDialogCheckboxChange = (e)=>{
    setDialogDepartmentCheckbox({...dialogDepartmentCheckbox,[e.target.name]:e.target.checked})
  }
  const [selecteDdepartment, setSelectedDepartment] = useState('');
  const handledepartmentSelectChange = async(e) => {
  try{
    let val = e.target.value ? e.target.value : ""
    let newDateStart = FormalizationStart()
    let newDatEnd = FormalizationEnd()
    let filters = {UserAuth:{ne: null}}
    if(permission === "2"){
      if(departmentCheckbox.outside === true && departmentCheckbox.inside === false){
        filters = {UserAuth:{eq: "1"}}
      }else{
        filters = {UserAuth:{ne: null}}
      }
    }else{
      if(departmentCheckbox.outside === true && departmentCheckbox.inside === false){
        filters = {UserAuth:{eq: "1"},and:[{Term_From: {le: newDateStart}},{Term_To: {ge:newDatEnd}}]}
      }else{
        filters = {UserAuth:{ne: null},and:[{Term_From: {le: newDateStart}},{Term_To: {ge:newDatEnd}}]}
      }
    }
    let dataAll = await getlistArCampaigns(filters,IsWorkday)
    if(val){
      dataAll = dataAll.filter((item)=>{
        if(item.Title === val){
          return true
        }else{
          return false
        }
    })
  }
    setCampaign(dataAll);
    setSelectedDepartment(val);
  }catch(error){
    if(error.message === 'No current user'){
      //ユーザープールに障害が発生した場合
      console.log('User pool is invalid.Please reauthenticate.');
      //ログイン画面に戻る
      signOut();
      }else{
      //他のエラーを処理する
      console.log('An error occurred:',error);
      }
   }
  };
  const [disableBtn,setDisableBtn] = useState(false)
  const getPermission = async()=>{
    let Permission = ""
  try{
    let user = await Auth.currentUserInfo();
      const agencyPriceGroup = await API.graphql(
        graphqlOperation(customerByeMail, {
            Agency_Email: user.attributes.email,
        })
      );
      const agentinfo = agencyPriceGroup.data.customerByeMail !== null ? agencyPriceGroup.data.customerByeMail["items"] : []
      if (agentinfo.length > 0) {
        Permission = agentinfo[0].Permission
      }
    }catch(error){
      if(error.message === 'No current user'){
        //ユーザープールに障害が発生した場合
        console.log('User pool is invalid.Please reauthenticate.');
        //ログイン画面に戻る
        signOut();
        }else{
        //他のエラーを処理する
        console.log('An error occurred:',error);
        }
    }
    return Permission
  }
  const [loaddingFlag, setLoadingFlag] = useState(false);
  const [IsWorkday, setIsWorkday] = useState([])
  const getIsWorkday = async () => {
    let holidays = [];
    const listArcalendarsList = await API.graphql(
      graphqlOperation(queryArcalendarIsWorkday, {
       IsWorkday:0,
        limit: 10000,
      }))
    if (listArcalendarsList.data.queryArcalendarIsWorkday !== null) {
      let nextToken = listArcalendarsList.data.queryArcalendarIsWorkday.nextToken;
      holidays = listArcalendarsList.data.queryArcalendarIsWorkday.items;
      while (nextToken !== null) {
        let InvDataSec = await API.graphql(
          graphqlOperation(queryArcalendarIsWorkday, {
           IsWorkday: 0,
            limit: 10000,
            nextToken: nextToken,
          }))
        nextToken = InvDataSec.data.queryArcalendarIsWorkday.nextToken;
        for (
          let i = 0;
          i < InvDataSec.data.queryArcalendarIsWorkday.items.length;
          i++
        ) {
          holidays.push(InvDataSec.data.queryArcalendarIsWorkday.items[i]);
        }
      }
    }
    return holidays;
  }
  useEffect(async() => {
    setLoadingFlag(true)
    // 非同期型（async）で在庫情報をagencyGroupInvListからAgency_Price_GroupをKeyに取得
    // 1) Auth.currentUserInfo() から、email情報を取得して、customerByeMailからeMailをKeyにAgent情報を取得
    //  ※ agencyGroupIDはAgent.jsで一度取得しているから再利用可能なはず！
    // 2) agencyGroupIDを取得して、これをKeyにagencyGroupInvListから在庫情報を取得
    try{
        let Permission = await getPermission()
        if (Permission !== "2") {
          setDisableBtn(true)
        } else {
          setDisableBtn(false)
        }
        let IsWorkdays = await getIsWorkday()
        await fetchPDFs(Permission,IsWorkdays);
        setIsWorkday(IsWorkdays)
        setPermission(Permission)
    }catch(error){
        if(error.message === 'No current user'){
          //ユーザープールに障害が発生した場合
          console.log('User pool is invalid.Please reauthenticate.');
          //ログイン画面に戻る
          signOut();
          }else{
          //他のエラーを処理する
          console.log('An error occurred:',error);
          }
      }
      setLoadingFlag(false)
  }, []);
  async function fetchPDFs(Permission,IsWorkday) {
    const userAuths = await userAuth();
    handleSetLoaddisabledflg(true);
    let filters = {}
    handleSetAuthFlg(userAuths)
    let newDateStart = FormalizationStart()
    let newDatEnd = FormalizationEnd()
    if (userAuths === "1") {
      if(Permission === "2"){
        filters = {UserAuth:{eq: "1" }}
      }else{
        filters = {UserAuth:{eq: "1" },and:[{Term_From: {le: newDateStart}},{Term_To: {ge:newDatEnd}}]}
      }
    } else {
      if(Permission === "2"){
        filters = {UserAuth:{ne: null}}
      }else{
        filters = {and:[{Term_From: {le: newDateStart}},{Term_To: {ge:newDatEnd}}]}
      }
    }
    handleSetAuthFlg(userAuths)
    const apiData = await getlistArCampaigns(filters,IsWorkday);
    setCampaign(apiData);
    handleSetLoaddisabledflg(false);
    select_row = [];
  }
  async function fetchPDFsNoclear() {
    handleSetLoaddisabledflg(true);
    let filters = {}
    let newDateStart = FormalizationStart()
    let newDatEnd = FormalizationEnd()
      if(permission === "2"){
        filters = {UserAuth:{ne: null}}
      }else{
        filters = {and:[{Term_From: {le: newDateStart}},{Term_To: {ge:newDatEnd}}]}
      }
    const apiData = await getlistArCampaigns(filters,IsWorkday);
    setCampaign(apiData);
    handleSetLoaddisabledflg(false);
  }
  // const loginGroup = useRef(null);
  // useEffect(() => {
  //   // 1) Auth.currentSession() から、Cogniteのユーザグループ情報を取得
  //   async function getLoginGroup() {
  //     // get the Login User Group from the top of the cognito groups list
  //     const { accessToken } = await Auth.currentSession();
  //     const cognitogroups = accessToken.payload['cognito:groups'];
  //     loginGroup.current = cognitogroups[0];
  //   }
  //   getLoginGroup();
  // }, []);
const FormalizationStart = ()=>{
  let format = "";
    let nTime = new Date();
    format += nTime.getFullYear() + "-";
    format +=
      nTime.getMonth() + 1 < 10
        ? "0" + (nTime.getMonth() + 1)
        : nTime.getMonth() + 1;
    format += "-";
    format += nTime.getDate() < 10 ? "0" + nTime.getDate() : nTime.getDate();
    return format
}
const FormalizationEnd = ()=>{
  let format = "";
    let nTime = new Date();
    format += nTime.getFullYear() + "-";
    format +=
      nTime.getMonth() + 1 < 10
        ? "0" + (nTime.getMonth() + 1)
        : nTime.getMonth() + 1;
    format += "-";
    format += nTime.getDate() < 10 ? "0" + nTime.getDate() : nTime.getDate();
    return format
}
  function addClick() {
    handleClickOpen1();
    handleSetNameText("");
    setfileData([])
    setFileList([])
    setFilesList({fileNames:[],PDFurl:[],filePDF:[]})
    setDialogDepartmentCheckbox({
      outside:false,
      inside:false
    })
    let format = "";
    let nTime = new Date();
    format += nTime.getFullYear() + "-";
    format +=
      nTime.getMonth() + 1 < 10
        ? "0" + (nTime.getMonth() + 1)
        : nTime.getMonth() + 1;
    format += "-";
    format += nTime.getDate() < 10 ? "0" + nTime.getDate() : nTime.getDate();
    handleSetDisabled(false);
    handleSetTerm_From(format);
    handleSetTerm_To(format);
    //handleSetDL('');
    handleSetText("");
    handleSetOperflg(1);
  }
  const [filesList,setFilesList] = useState({fileNames:[],PDFurl:[],filePDF:[]})
  const handleDeleteFile = async(file)=>{
    let fileName = filesList.fileNames
    let filePDFs = filesList.filePDF
    let PDFurls = filesList.PDFurl
    for(let i = 0;i < fileName.length;i++){
      if(fileName[i] === file){
        await Storage.remove(
          filePDFs[i],
          PDFurls[i]
        );
        fileName.splice(i, 1)
        filePDFs.splice(i, 1)
        PDFurls.splice(i, 1)
      }
    }
    let obj = {fileNames:JSON.parse(JSON.stringify(fileName)),PDFurl:PDFurls,filePDF:filePDFs}
    setFilesList(obj)
  }
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    bgColor: "white",
    headerColumnStyle: {
      backgroundColor: "white",
      borderBottom: "3px solid #804F29",
      borderRight:"none"
    },
    selectColumnStyle:{borderRight : 'none'},
    onSelect: (row, isSelect) => {
      if (isSelect) {
        select_row.push(row.id);
      } else {
        let find = select_row.findIndex(function (item) {
          return item === row.id;
        });
        select_row.splice(find, 1);
      }
    },
    onSelectAll: (isSelect) => {
      if (isSelect) {
        for (let i = 0; i < campaign.length; i++) {
          select_row.push(campaign[i].id);
        }
      } else {
        select_row = [];
      }
    },
  };
  // function searchClick() {
  //   if (select_row === "" || select_row.length !== 1) {
  //     handleSetMsgId("警告");
  //     handleSetMsgText("データを一つだけ選択してください");
  //     handleSetMsgBtnOK("はい");
  //     handleClickOpen2();
  //     return;
  //   }
  //   setOpen1(true);
  //   handleSetDisabled(true);
  //   handleSetError("");
  //   handleSetErrorFlg(true);
  //   let Term_From = "";
  //   let Term_To = "";
  //   for (let i = 0; i < campaign.length; i++) {
  //     if (select_row[0] === campaign[i].id) {
  //       Term_From = campaign[i].Term_From.replace(" ", "T");
  //       Term_To = campaign[i].Term_To.replace(" ", "T");
  //       handleSetNameText(campaign[i].Title);
  //       handleSetText(campaign[i].Text);
  //       break;
  //     }
  //   }
  //   handleSetTerm_From(Term_From);
  //   handleSetTerm_To(Term_To);
  //   //handleSetDL(campaign[index].Wholes_Rate_Condision)
  //   handleSetOperflg(2);
  // }
  function addupdateClick() {
    setfileData([])
    setFileList([])
    setFilesList({fileNames:[],PDFurl:[],filePDF:[]})
    if (select_row.length !== 1) {
      handleSetMsgId("更新警告");
      handleSetMsgText("データを一つだけ選択してください");
      handleSetMsgBtnOK("はい");
      handleClickOpen2();
      return;
    }
    setOpen1(true);
    handleSetDisabled(false);
    let Term_To = "";
    let Term_From = "";
    for (let i = 0; i < campaign.length; i++) {
      if (select_row[0] === campaign[i].id) {
        Term_From = campaign[i].Term_From
        Term_To = campaign[i].Term_To
        handleSetNameText(campaign[i].Title);
        handleSetText(campaign[i].Text);
        let fileNames = JSON.parse(campaign[i].fileName)
        let PDFurls = JSON.parse(campaign[i].PDFurl)
        let filePDFs = JSON.parse(campaign[i].filePDF)
        if(campaign[i].UserAuth === "2"){
          setDialogDepartmentCheckbox({outside:false,inside:true})
        }else if(campaign[i].UserAuth === "1"){
          setDialogDepartmentCheckbox({outside:true,inside:false})
        }else if(campaign[i].UserAuth === "3"){
          setDialogDepartmentCheckbox({outside:true,inside:true})
        }else{
          setDialogDepartmentCheckbox({outside:false,inside:false})
        }
        setFilesList({fileNames:fileNames ? fileNames : [],PDFurl:PDFurls ? PDFurls : [],filePDF:filePDFs ? filePDFs : []})
        break;
      }
    }
    handleSetTerm_From(Term_From);
    handleSetTerm_To(Term_To);
    //handleSetDL(campaign[index].Wholes_Rate_Condision)

    handleSetOperflg(2);
  }

  // function arAttentionsClick() {
  //   let vid = inventories.id;
  //   let vtitile = inventories.Title
  //   let vNotification = inventories.Notification;
  //   let vDiscription = inventories.Discription;
  //   API.graphql(graphqlOperation(updateArAttention, { input: { id: vid, Title: vtitile, Notification: vNotification, Discription: vDiscription } }));

  // }

  const [nameText, setNameText] = React.useState('');
  const handleSetNameText = (Object) => {
    setNameText(Object);
  };

  const [loaddisabledflg, setLoaddisabledflg] = React.useState(Object);
  const handleSetLoaddisabledflg = (Object) => {
    setLoaddisabledflg(Object);
  };
  const [authFlg, setAuthFlg] = React.useState(Object);
  const handleSetAuthFlg = (Object) => {
    setAuthFlg(Object);
  };

  const [disabledflg, setDisabled] = React.useState(Object);
  const handleSetDisabled = (Object) => {
    setDisabled(Object);
  };

  function txtChangedText(e) {
    handleSetNameText(e.target.value);
  }
  const [Term_FromText, setTerm_From] = React.useState(Object);
  const handleSetTerm_From = (Object) => {
    setTerm_From(Object);
  };
  function txtChangedTerm_From(e) {
    handleSetTerm_From(moment(e).format('YYYY-MM-DD'));
  }
  const [Term_ToText, setTerm_To] = React.useState(Object);
  const handleSetTerm_To = (Object) => {
    setTerm_To(Object);
  };
  function txtChangedTerm_To(e) {
    handleSetTerm_To(moment(e).format('YYYY-MM-DD'));
  }
  // const [DLText, setDL] = React.useState(Object);
  // const handleSetDL = (Object) => {
  //   setDL(Object);
  // };
  // function txtChangedDL(e) {
  //   handleSetDL(e.target.value)
  // }

  const [texttext, setText] = React.useState(Object);
  const handleSetText = (Object) => {
    setText(Object);
  };
  function txtChangedtext2(e) {
    handleSetText(e.target.value);
  }
  const [operflg, setOperflg] = React.useState(Object);
  const handleSetOperflg = (Object) => {
    setOperflg(Object);
  };

  const [msg1, setMsgId] = React.useState(Object);
  const handleSetMsgId = (Object) => {
    setMsgId(Object);
  };
  const [msgText, setMsgText] = React.useState(Object);
  const handleSetMsgText = (Object) => {
    setMsgText(Object);
  };
  const [msgbtnOK, setMsgBtnOK] = React.useState(Object);
  const handleSetMsgBtnOK = (Object) => {
    setMsgBtnOK(Object);
  };
  const [msgbtnNG, setMsgBtnNG] = React.useState(Object);
  const handleSetMsgBtnNG = (Object) => {
    setMsgBtnNG(Object);
  };

  const [fileData, setfileData] = React.useState([]);
  const handleSetfileData = (files) => {
    setfileData(files);
  };
  const [fileList, setFileList] = useState([]);
  const fileinputChange = (event) => {
    let files = event.target.files;
    let fileNames = [];
    for (let i = 0; i < files.length; i++) {
      fileNames.push(files[i].name)
    }
    setFileList(fileNames);
    handleSetfileData(files);
  };
  function updateClick() {
    createArCampaign1(fileData);
  }
  const { aws_user_files_s3_bucket: bucket } = config;
  const createArCampaign1 = async (files) => {
    setLoadingFlag(true)
    try{
    let name = nameText
    let UserAuth = ""
    if (name === "") {
      handleSetMsgId("エラー");
      handleSetMsgText("部門を選択してください");
      handleSetMsgBtnOK("はい");
      handleClickOpen2();
      setLoadingFlag(false)
      return;
    }
    if(dialogDepartmentCheckbox.inside){
      if(dialogDepartmentCheckbox.outside){
        UserAuth = "3"
      }else{
        UserAuth = "2"
      }
    }else{
      UserAuth = "1"
    }
    let Term_From2 = Term_FromText
    let Term_To2 = Term_ToText
    if(Term_From2 >= Term_To2){
      handleSetMsgId("エラー");
      handleSetMsgText("終了が開始より大きくしてください");
      handleSetMsgBtnOK("はい");
      handleClickOpen2();
      setLoadingFlag(false)
      return;
    }
    if(!dialogDepartmentCheckbox.inside && (!dialogDepartmentCheckbox.outside)){
      handleSetMsgId("エラー");
      handleSetMsgText("区分を選択してください");
      handleSetMsgBtnOK("はい");
      handleClickOpen2();
      setLoadingFlag(false)
      return;
    }
    let text = texttext
    if (text === "") {
      handleSetMsgId("エラー");
      handleSetMsgText("タイトルを入力してください");
      handleSetMsgBtnOK("はい");
      handleClickOpen2();
      setLoadingFlag(false)
      return;
    }
    if (operflg === 1) {
      if(files.length > 0){
      const region = "ap-northeast-1";
      let allowedTypes = ['pdf','xls','xlsx','doc','docx','msg'];
      for(let i = 0;i < files.length;i++){
        let fileType = files[i].name.split('.').pop()
        if (allowedTypes.indexOf(fileType) === -1) {
          handleSetMsgId("エラー");
          handleSetMsgText("PDF/WORD/EXCEL/メール　どれかを添付してください");
          handleSetMsgBtnOK("はい");
          handleClickOpen2();
          setFileList([])
          setLoadingFlag(false)
          return;
        }
        let maxSize = 10 * 1024 * 1024; // 10MB
      if (files[i].size > maxSize) {
        handleSetMsgId("エラー");
          handleSetMsgText("ファイルサイズを10MB以内にしてください");
          handleSetMsgBtnOK("はい");
          handleClickOpen2();
          setFileList([])
        setLoadingFlag(false)
        return;
      }
      }
      if (files.length > 0) {
        try {
          let key = []
          let url = []
          for(let i = 0 ; i < files.length;i++){
            const extension = files[i].name.split(".")[1];
            const imgName = files[i].name.split(".")[0] + uuidv4();
            const { type: mimeType } = files[i];
            let keycopy = "pdf/" + `${imgName}.${extension}`
              key.push("pdf/" + `${imgName}.${extension}`);
              url.push(`https://${bucket}.s3.${region}.amazonaws.com/public/${keycopy}`);
              await Storage.put(keycopy, files[i], {
                contentType: mimeType,
              });
            }
          await API.graphql(
            graphqlOperation(createArCampaign, {
              input: {
                id: uuidv4(),
                filePDF: JSON.stringify(key),
                Term_From: Term_From2,
                Term_To: Term_To2,
                Title: name,
                PDFurl:JSON.stringify(url),
                Text: text,
                UserAuth:UserAuth,
                fileName:JSON.stringify(fileList),
                updatedAt:Term_From2
              },
            })
          );
          fetchPDFsNoclear();
        } catch (err) {
          console.log("error: ", err);
        }
      }
    }else{
      try{
        await API.graphql(
          graphqlOperation(createArCampaign, {
            input: {
              id: uuidv4(),
              Term_From: Term_From2,
              Term_To: Term_To2,
              Title: name,
              Text: text,
              UserAuth:UserAuth,
              updatedAt:Term_From2
            },
          })
        );
        fetchPDFsNoclear();
      }catch (err) {
        if(err.message === 'No current user'){
          signOut();
          }else{
          console.log('An error occurred:',err);
          }
        }
      }
    } else {
      let update = Term_From2;
      if(moment(Term_From2).format('YYYYMMDD') < moment(new Date()).format('YYYYMMDD')){
        update = moment(new Date()).format('YYYY-MM-DD')
      }
      if (files.length !== 0) {
        let allowedTypes = ['pdf','xls','xlsx','doc','docx','msg'];
      for(let i = 0;i < files.length;i++){
        let fileType = files[i].name.split('.').pop()
        if (allowedTypes.indexOf(fileType) === -1) {
          handleSetMsgId("エラー");
          handleSetMsgText("PDF/WORD/EXCEL/メール　どれかを添付してください");
          handleSetMsgBtnOK("はい");
          handleClickOpen2();
          setFileList([])
          setLoadingFlag(false)
          return;
        }
        let maxSize = 10 * 1024 * 1024; // 10MB
      if (files[i].size > maxSize) {
        handleSetMsgId("エラー");
          handleSetMsgText("ファイルサイズを10MB以内にしてください");
          handleSetMsgBtnOK("はい");
          handleClickOpen2();
          setFileList([])
        setLoadingFlag(false)
        return;
      }
      }
        try {
          const region = "ap-northeast-1";
          // OLD削除
          for (let i = 0; i < campaign.length; i++) {
            if (select_row[0] === campaign[i].id) {
              let key = []
              let url = []
              let arr = filesList.fileNames ? JSON.parse(JSON.stringify(filesList.fileNames)) : []
              if(filesList.fileNames && filesList.fileNames.length > 0){
                for(let i = 0;i < filesList.fileNames.length;i++){
                  key.push(filesList.filePDF[i]);
                  url.push(filesList.PDFurl[i])
                }
              }
              for(let i = 0 ; i < files.length;i++){
                const extension = files[i].name.split(".")[1];
                const imgName = files[i].name.split(".")[0] + uuidv4();
                const { type: mimeType } = files[i];
                arr.push(files[i].name)
                  key.push("pdf/" + `${imgName}.${extension}`);
                  let keycopy = "pdf/" + `${imgName}.${extension}`
                  url.push(`https://${bucket}.s3.${region}.amazonaws.com/public/${keycopy}`);
                  await Storage.put(keycopy, files[i], {
                    contentType: mimeType,
                  });
                }
              await API.graphql(
                graphqlOperation(updateArCampaign, {
                  input: {
                    id: campaign[i].id,
                    filePDF: JSON.stringify(key),
                    Term_From: Term_From2,
                    Term_To: Term_To2,
                    Title: name,
                    PDFurl: JSON.stringify(url),
                    Text: text,
                    UserAuth:UserAuth,
                    fileName:JSON.stringify(arr),
                    updatedAt:update
                  },
                })
              );
              break;
            }
          }
          fetchPDFsNoclear();
        } catch (err) {
          console.log("error: ", err);
        }
      } else {
        let key = []
        let url = []
        let arr = filesList.fileNames ? JSON.parse(JSON.stringify(filesList.fileNames)) : []
        if(filesList.fileNames && filesList.fileNames.length > 0){
          for(let i = 0;i < filesList.fileNames.length;i++){
            key.push(filesList.filePDF[i]);
            url.push(filesList.PDFurl[i])
          }
        }
        try {
          await API.graphql(
            graphqlOperation(updateArCampaign, {
              input: {
                id: select_row[0],
                Term_From: Term_From2,
                Term_To: Term_To2,
                Title: name,
                Text: text,
                UserAuth:UserAuth,
                updatedAt:update,
                fileName:JSON.stringify(arr),
                PDFurl: JSON.stringify(url),
                filePDF: JSON.stringify(key),
              },
            })
          );
          fetchPDFsNoclear();
        } catch (err) {
          console.log("error: ", err);
        }
      }
    }
  }catch(error){
    if(error.message === 'No current user'){
      //ユーザープールに障害が発生した場合
      console.log('User pool is invalid.Please reauthenticate.');
      //ログイン画面に戻る
      signOut();
      }else{
      //他のエラーを処理する
      console.log('An error occurred:',error);
      }
   }
    setOpen1(false);
    setLoadingFlag(false)
    // 一時廃止
    // sendEmail();
  };

  function deleteClick() {
    if (select_row.length === 0) {
      handleSetMsgId("削除警告");
      handleSetMsgText("削除データを選択してください");
      handleSetMsgBtnOK("はい");
      handleClickOpen2();
      return;
    }
    handleSetMsgId("削除確認");
    handleSetMsgText("削除を実行してもいいですか");
    handleSetMsgBtnOK("はい");
    handleSetMsgBtnNG("いいえ");
    handleClickOpen();
  }

  const deleteArCampaigns = async () => {
    setLoadingFlag(true)
    for (let i = 0; i < select_row.length; i++) {
      try {
        for (let j = 0; j < campaign.length; j++) {
          if (select_row[i] === campaign[j].id) {
            if (campaign[j].filePDF) {
              let filePDFs = JSON.parse(campaign[j].filePDF)
              let PDFurls = JSON.parse(campaign[j].PDFurl);
              for(let i = 0 ;i < filePDFs.length;i++){
                if(filePDFs[i]){
                  await Storage.remove(
                    filePDFs[i],
                    PDFurls[i]
                  );
                }
              }
            }
            await API.graphql(graphqlOperation(deleteArCampaign, {
                input: { id: campaign[j].id },
              })
            );
            break;
          }
        }
        handleSetMsgId("削除正常");
        handleSetMsgText("削除実行成功しました！");
        handleSetMsgBtnOK("はい");
        handleClickOpen2();
        fetchPDFs(permission,IsWorkday);
        setLoadingFlag(false)
      } catch (err) {
        console.log("error: ", err);
      }
    }
    setLoadingFlag(false)
  };
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleCloseNG2 = () => {
    setOpen2(false);
  };
  const handleClose1 = () => {
    updateClick();
  };
  const handleClose2 = () => {
    setOpen1(false);
  };
  const handleCloseNG = () => {
    setOpen(false);
  };
  const handleCloseOK = () => {
    deleteArCampaigns();
    setOpen(false);
  };
  const handleCloseOK2 = () => {
    setOpen2(false);
  };

  // const sendEmail = async()=>{
  //   let AgencyEmails = [];
  //   let filter = {
  //     Agency_id: {
  //       notContains: "arbrown" // filter priority = 1
  //     }
  // };
  //  let listAllArAgencys = await API.graphql({ query: listAllArAgency, variables: { filter: filter}})
  //   let listAgencys = listAllArAgencys.data.listAllArAgency['items'];
  //   listAgencys.forEach(item => {
  //     AgencyEmails.push(item['Agency_Email'])
  //   });
  //   console.log("AgencyEmails",AgencyEmails)
  //  let bodyAgency = {
  //     templete: "a1",
  //     subject: "お知らせがUPされた",
  //     msgUrl: global.ArGlob.WebHostAddress + "/Home",
  //     txtEnd: "",
  //     receiveUser: "Agency",
  //     toAgencyEmails: AgencyEmails,
  //     message:
  //       "代理店御中<br><br>ARBOSをご利用いただきありがとうございます。<br><br>お知らせをアップロードしましたので、ご確認ください<br><br>",
  //   };
  //   const body = JSON.stringify(bodyAgency);
  //   const requestOptions = {
  //     method: "POST",
  //     body
  //   };

  //   fetch(global.ArGlob.EmailEndPoint, requestOptions)
  //     .then((response) => {
  //       if (response.ok) {
  //        console.log("send email success")
  //       }
  //       return response.json();
  //     })
  //     .then((response) => {
  //       console.log("成功", response);
  //     })
  //     .catch((error) => {
  //       console.log("失敗", error);
  //     });
  // }
  // function txtChanged1(e) {
  //   inventories.Title = e.target.value
  //   setInv({ type: SET, invs: inventories })
  // }
  // function txtChanged2(e) {
  //   inventories.Discription = e.target.value
  //   setInv({ type: SET, invs: inventories })
  // }
  // function txtChanged3(e) {
  //   inventories.Notification = e.target.value
  //   setInv({ type: SET, invs: inventories })
  // }

  return (
    <Container
      style={{ backgroundColor: "", minWidth: "100%", minHeight: "74vh" }}
    >
      <div>
        <Meta title={pageTitle} />
      </div>
      <Row>
        <h4
          style={{
            color: "black",
            borderLeft: "4px solid #804F29",
            paddingLeft: "5px",
            marginTop: "1rem",
            marginLeft: "1rem",
          }}
        >
          お知らせ
        </h4>
        <div style={{display: authFlg === "1" ? "none" : "flex",alignItems:"center", marginTop: "1rem",marginLeft: "2rem"}}>
          <p style={{display:"flex",alignItems:"center"}} className={classes.checkboxLabelRight}>
              <input type="checkbox" style={{height:16,width:16}}name="outside" value={departmentCheckbox.outside} onClick={(e)=>handleOutsideCheckboxChange(e)}></input>
              <span className={classes.checkboxLabel}>社外</span>
          </p>
          <p style={{display:"flex",alignItems:"center"}}>
              <input type="checkbox" style={{height:16,width:16}} name="inside" value={departmentCheckbox.inside} onClick={(e)=>handleInsideCheckboxChange(e)}></input>
              <span className={classes.checkboxLabel}>ALL</span>
          </p>
          <div>
             <InputGroup className="mb-3" style={{ height: "30px", marginLeft: "1rem"}}>
                 <label className={classes.textlabelOrder}>
                     <span>部門</span>
                 </label>
                 <span>
                     <FormControlsk variant="outlined" className={classes.formControl}>
                        <Select
                          className="MuiSelect-filled.MuiSelect-filled"
                          style={{ width: "55ch", height: "30px"}}
                         value={selecteDdepartment || ""}
                         name="selecteDdepartment"
                         id="selecteDdepartment"
                         onChange={(e) => {handledepartmentSelectChange(e)}}
                         MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                        >
                          <MenuItem key="" value="" >　</MenuItem>
                          <MenuItem key="ライフサイエンス部" value="ライフサイエンス部" >ライフサイエンス部</MenuItem>
                          <MenuItem key="電子材料部_シリコーン" value="電子材料部_シリコーン">電子材料部_シリコーン</MenuItem>
                        </Select>
                      </FormControlsk>
                 </span>
             </InputGroup>
            </div>
        </div>
      </Row>
      <Row>
        <Col>
          <div className={classes.root}>
            <BootstrapTable
              bootstrap4
              striped={true}
              hover={true}
              headerStyle
              keyField="id"
              // caption={<CaptionElement />}
              data={campaign}
              columns={columns}
              headerClasses={headerStyle}
              defaultSorted={defaultSorted}
              //expandRow={expandRow}
              condensed
              selectRow={selectRow}
              noDataIndication={"no results found"}
            />
          </div>
          <div hidden={authFlg === "1" ? true : false} className={classes.button}>
            {/* <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group"> */}
            <Row>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                disabled={loaddisabledflg ? loaddisabledflg : disableBtn}
                onClick={() => {
                  addClick();
                }}
              >
                {" "}
                新規
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                disabled={loaddisabledflg ? loaddisabledflg : disableBtn}
                onClick={() => {
                  addupdateClick();
                }}
              >
                更新
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                disabled={loaddisabledflg ? loaddisabledflg : disableBtn}
                onClick={() => {
                  deleteClick();
                }}
              >
                削除
              </Button>
              {/* <Button
                 variant="contained"
                 color="primary"
                 size="medium"
                 disabled={loaddisabledflg}
                 onClick={() => {
                   searchClick();
                 }}
               >
                 詳細参照
               </Button> */}
            </Row>
          </div>
          <div hidden={!authFlg} className={classes.button}>
            {/* <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group"> */}
            <Row>
              {/* <Button
                 variant="contained"
                 color="primary"
                 size="medium"
                 disabled={loaddisabledflg}
                 onClick={() => {
                   searchClick();
                 }}
               >
                 詳細参照
               </Button> */}
            </Row>
            {/* </ButtonGroup> */}
          </div>
          <Dialog
            open={open1}
            onClose={handleClose1}
            fullWidth="lg"
            maxWidth="lg"
            aria-labelledby="max-width-dialog-title"
            disableEscapeKeyDown={true}
          >
            <DialogTitle id="alert-dialog-title">
              お知らせ
            </DialogTitle>
            <DialogContent>
              <Row>
                <Col>
                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      className={classes.textField}
                      id="basic-name"
                    >
                      部門<span style={{ color: "red" }}>［必須］ </span>
                    </InputGroup.Text>
                    <div style={{height:'35px'}}>
                      <InputGroup className="mb-3">
                          <span>
                              <FormControlsk variant="outlined" className={classes.DialogformControl}>
                                  <Select
                                    className={classes.dialogSelect}
                                    style={{ width: "29ch", height: "35px"}}
                                  value={nameText || ""}
                                  id="name"
                                  name="nameText"
                                  disabled={disabledflg}
                                  onChange={(e) => { txtChangedText(e) }}
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    },
                                    transformOrigin: {
                                      vertical: 'top',
                                      horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                  }}
                                  >
                                    <MenuItem key="" value="" >　</MenuItem>
                                    <MenuItem key="ライフサイエンス部" value="ライフサイエンス部" >ライフサイエンス部</MenuItem>
                                  <MenuItem key="電子材料部_シリコーン" value="電子材料部_シリコーン">電子材料部_シリコーン</MenuItem>
                                  </Select>
                                </FormControlsk>
                          </span>
                      </InputGroup>
                    </div>
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      className={classes.textField}
                      id="basic-from"
                    >
                      開始<span style={{ color: "red" }}>［必須］ </span>
                    </InputGroup.Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
                      <DatePicker
                      id="Term_From"
                      label="Next appointment"
                      defaultValue= ""
                      className={classes.textField}
                      value={Term_FromText}
                      onChange={(e) => {
                        txtChangedTerm_From(e);
                      }}
                      disabled={disabledflg}
                      disablePast
                      disableToolbar
                      variant="standard"
                      format="yyyy/MM/dd"
                      helperText=""
                      error={false}
                      />
                    </MuiPickersUtilsProvider>
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      className={classes.textField}
                      id="basic-To"
                    >
                      終了<span style={{ color: "red" }}>［必須］ </span>
                    </InputGroup.Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
                      <DatePicker
                      id="Term_To"
                      label="Next appointment"
                      defaultValue = ""
                      className={classes.textField}
                      value={Term_ToText}
                      onChange={(e) => {
                        txtChangedTerm_To(e);
                      }}
                      disabled={disabledflg}
                      disablePast
                      disableToolbar
                      variant="standard"
                      format="yyyy/MM/dd"
                      helperText=""
                      error={false}
                      />
                    </MuiPickersUtilsProvider>
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text className={classes.textField} id="basic-To"> 区分<span style={{ color: "red" }}>［必須］ </span>
                    </InputGroup.Text>
                    <div style={{display:"flex"}} className={classes.textField}>
                        <p style={{display:"flex",alignItems:"center"}} className={classes.checkboxLabelRight}>
                            <input type="checkbox" style={{height:16,width:16}}name="outside" defaultChecked={dialogDepartmentCheckbox.outside} onClick={(e)=>handleDialogCheckboxChange(e)}></input>
                            <span className={classes.checkboxLabel}>社外</span>
                        </p>
                        <p style={{display:"flex",alignItems:"center"}}>
                            <input type="checkbox" style={{height:16,width:16}} name="inside" defaultChecked={dialogDepartmentCheckbox.inside} onClick={(e)=>handleDialogCheckboxChange(e)}></input>
                            <span className={classes.checkboxLabel}>ALL</span>
                        </p>
                    </div>
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      className={classes.textField}
                      id="basic-text"
                    >
                      タイトル<span style={{ color: "red" }}>［必須］</span>
                    </InputGroup.Text>
                    <FormControl
                      placeholder="詳細内容"
                      as="textarea"
                      value={texttext}
                      className={classes.textField}
                      rows="6"
                      aria-label="With textarea"
                      id="text"
                      onChange={(e) => {
                        txtChangedtext2(e);
                      }}
                      disabled={disabledflg}
                    ></FormControl>
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup>
                      <InputGroup.Text
                        className={classes.textField}
                        id="basic-text"
                      >
                        ファイル<span style={{ color: "red" }}> </span>
                      </InputGroup.Text>
                      <input
                        accept=".pdf,.xls,.xlsx,.doc,.docx,.msg"
                        className={classes.input}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={fileinputChange}
                        disabled={disabledflg}
                      />
                      <ul style={{width:"57%",margin:"auto"}}>
        {filesList.fileNames && filesList.fileNames.map((file) => (
          <li key={file}>{file}<DeleteIcon onClick={()=>handleDeleteFile(file)} style = {{cursor:"pointer"}}>削除</DeleteIcon></li>
        ))} </ul>
                    </InputGroup>
                  </InputGroup>
                </Col>
              </Row>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose1}
                disabled={disabledflg}
                variant="contained"
                color="primary"
                autoFocus
              >
                登録
              </Button>
              <Button
                onClick={handleClose2}
                variant="contained"
                color="primary"
              >
                キャンセル
              </Button>
            </DialogActions>
          </Dialog>
        </Col>
      </Row>
      <CircularProgress style={{display:loaddingFlag === true ? "block" : "none",position: 'fixed',top: '50%', left: '50%', margin:"auto",zIndex:10000}}/>
      <Dialog
        open={open}
        onClose={handleCloseNG}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{msg1}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {msgText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOK} color="primary" autoFocus>
            {msgbtnOK}
          </Button>
          <Button onClick={handleCloseNG} color="primary" autoFocus>
            {msgbtnNG}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open2}
        onClose={handleCloseNG2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{msg1}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {msgText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOK2} color="primary" autoFocus>
            {msgbtnOK}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Home;
